<template>
    <section class="widget new-updated-design">
        <vue-snotify></vue-snotify>
        <div>
            <div class="row">
                <div class="col-md-7 grid-margin grid-margin-md-0 stretch-card">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title text-left">{{ $t('spotters-list') }}</h4>
                        </div>
                        <div class="card-body new-card-body">
                            <vue-good-table
                                    mode="remote"
                                    @on-page-change="onPageChange"
                                    @on-sort-change="onSortChange"
                                    @on-column-filter="onColumnFilter"
                                    @on-per-page-change="onPerPageChange"
                                    :select-options="{ enabled: true }"
                                    @on-selected-rows-change="selectionChanged"
                                    :totalRows="totalRecords"
                                    :isLoading.sync="isLoading"
                                    :pagination-options="{enabled: true,dropdownAllowAll: false,perPageDropdown: [10, 20, 50, 100, 200],}"
                                    :rows="rows"
                                    :columns="columns"
                                    @on-search="searchFn"
                                    :search-options="{enabled: true,searchFn: searchFn}">
                                <template slot="table-row" slot-scope="props">
                                        <span
                                                v-if="props.column.field === 'action'"
                                                class="text-nowrap"
                                        >
                                            <b-button size="sm" @click="editSpotter(props.row.id)"
                                                      class="mr-1 btn-info text-white">
                                        View Profile
                                    </b-button>
                                    <b-button size="sm" @click="deleteSpotter(props.row.id)"
                                              class="mr-1 btn-danger text-white">
                                        Delete
                                    </b-button>
                                        </span>
                                </template>
                            </vue-good-table>
                        </div>
                    </div>
                </div>


                <div class="col-md-5 grid-margin grid-margin-md-0">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title text-left">{{ $t('welcome-new-spotters') }}</h4>
                        </div>
                        <form @submit.stop.prevent="onSubmitEditor">
                            <div class="card-body new-card-body">
                                <!-- <h3 class="card-title">Editor For App Field Guide Info</h3> -->
                                <b-form-group label="Send From:" hidden>
                                    <b-form-input
                                            type="text"
                                            id="email_send"

                                            v-model="editorForm.email_send"
                                    >

                                    </b-form-input>
                                </b-form-group>
                                <b-form-group :label="$t('subject-line')">
                                    <b-form-input
                                            type="text"
                                            id="subject_line"

                                            v-model="editorForm.subject_line"
                                    >

                                    </b-form-input>
                                </b-form-group>
                                <ckeditor :editor="editor" v-model="editorForm.editor_content" :config="editorConfig"
                                          :height="120" :rows="6" @input="onEditorInput" @ready="onReady">
                                    <div class="document-editor">
                                        <div class="document-editor__toolbar"></div>
                                        <div class="document-editor__editable-container">
                                            <div class="document-editor__editable">
                                                <p>The initial editor data.</p>
                                            </div>
                                        </div>
                                    </div>
                                </ckeditor>
                                <div class="card mt-3">
                                    <div class="card-body new-card-body">
                                        <div class="row">
                                            <div class="col-md-6 col-sm-9 col-xs-9 text-left">
                                                <label class="" for="inline-form-custom-select-pref">{{ $t('auto-send-signup-label') }}</label>
                                            </div>
                                            <div class="col-md-6 col-sm-3 col-xs-3">
                                                <toggle-button v-model="editorForm.auto_send_upon_signup" :sync=true
                                                               class="switchColor"/>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 col-sm-9 col-xs-9 text-left">
                                                <label class="" for="inline-form-custom-select-pref">{{ $t('auto-send-import-label') }}</label>
                                            </div>
                                            <div class="col-md-6 col-sm-3 col-xs-3">
                                                <toggle-button v-model="editorForm.auto_send_upon_import" :sync=true
                                                               class="switchColor"/>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4"></div>
                                    <div class="col-md-12">
                                        <b-button type="submit" variant="success" class="mt-3 ml-2 float-right"
                                                  :disabled="isDisabled1">{{ $t('save-template-btn') }}
                                        </b-button>
                                        <b-button type="button" variant="success" class="mt-3  float-right"
                                                  :disabled="isDisabled || isLoading2" @click="sendWelcomeEmail">
                                                  <template v-if="isLoading2">
                                        <i class="fas fa-spinner fa-spin"></i> {{ $t('sending-mail') }}...
                                    </template>
                                    <template v-else>
                                        {{ $t('send-mail-btn') }}
                                    </template>
                                        </b-button>
                                    </div>
                                </div>

                            </div>
                        </form>
                        <div class="card-footer text-center">
                            <a :href="client_url" target="_blank">{{this.client_url}}</a>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </section>
</template>
<script>
    import VueTrix from "vue-trix";
    import API from '@/api'
    import moment from 'moment'
    import Snotify, {SnotifyPosition} from 'vue-snotify'
    import Vue from "vue";
    import CKEditor from '@ckeditor/ckeditor5-vue2';
    // import DecoupledDocument from '@ckeditor/ckeditor5-build-decoupled-document';
    import CkEditorBuild from 'ckeditor5-custom-build/build/ckeditor';
    import UploadAdapter from '@/UploadAdapter';
    import {validationMixin} from "vuelidate";
    import {required, minLength, email, requiredIf, sameAs} from "vuelidate/lib/validators";

    const options = {
        toast: {
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(Snotify, options)
    Vue.use(CKEditor);
    export default {
        name: 'textEditor',
        // name: 'notifications',
        mixins: [validationMixin],
        components: {},
        data() {
            return {
                text: '',
                client_url: '',
                serverParams: {
                    // a map of column filters example: {name: 'john', age: '20'}
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    columnFilters: {},
                    sort: [
                        {
                            field: 'id', // example: 'name'
                            type: 'desc' // 'asc' or 'desc'
                        }
                    ],

                    page: 1, // what page I want to show
                    perPage: 10 // how many items I'm showing per page
                },
                columns: [
                    {
                        label: 'Name',
                        field: 'name',
                        filterable: true
                    },
                    {
                        label: 'Phone',
                        field: 'mobile_phone',
                        sortable: true,
                    },
                    {
                        label: 'Email',
                        field: "email",
                        sortable: true,
                    },
                    {
                        label: 'Location',
                        field: "address",
                        sortable: true,
                    },
                ],
                editorForm: {
                    editor_content: "",
                    email_send: "no-reply@spotternet.com",
                    auto_send_upon_signup: '',
                    subject_line: '',
                    auto_send_upon_import: '',
                    client_id: JSON.parse(localStorage.getItem('client_id')),

                },
                editor: CkEditorBuild,
                editorConfig: {
                    extraPlugins: [this.uploader],
                    toolbar: {
                        items: [
                            'heading',
                            '|',
                            'fontSize',
                            'fontFamily',
                            'fontColor',
                            'fontBackgroundColor',
                            '|',
                            'bold',
                            'italic',
                            'underline',
                            'strikethrough',
                            '|',
                            'alignment',
                            '|',
                            'numberedList',
                            'bulletedList',
                            '|',
                            'indent',
                            'outdent',
                            '|',
                            'link',
                            'blockQuote',
                            'imageUpload',
                            'insertTable',
                            'mediaEmbed',
                            '|',
                            'undo',
                            'redo',
                        ]
                    }, language: 'cs',
                    image: {
                        toolbar: [
                            'imageTextAlternative',

                            'imageStyle:side',
                        ]
                    },
                    table: {
                        contentToolbar: [
                            'tableColumn',
                            'tableRow',
                            'mergeTableCells'
                        ]
                    },
                },
                isLoading: false,
                isLoading2: false,
                rows: [],
                user_client_ids: [],
                totalRecords: 0,
                isDisabled: false,
                isDisabled1: false,
            };
        },
        validations: {
            text: {
                required,
            },
        },
        methods: {
            resetForm() {
                this.form.date_from = '';
                this.form.date_to = '';
            },
            loadItems() {
                this.isLoading = true;
                API.getAllNotificationSpotters(this.serverParams,
                    data => {
                        this.isLoading = false;
                        this.totalRecords = data.totalRecords;
                        this.rows = data.data.data;
                    },
                    err => {
                    }
                )
            },
            loadItem() {
                // API.getAllNotificationSpotters(this.serverParams,
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                }
                API.getEmailTemplate(
                    data,
                    data => {
                        console.log(data.data);
                        this.editorForm.editor_content = data.data.editor_content;
                        this.editorForm.email_send = data.data.email_send;
                        this.editorForm.subject_line = data.data.subject_line;
                        this.editorForm.auto_send_upon_signup = (data.data.auto_send_upon_signup === 1) ? true : false;
                        this.editorForm.auto_send_upon_import = (data.data.auto_send_upon_import === 1) ? true : false;
                        // this.editorForm.auto_send_upon_signup = data.data.auto_send_upon_signup;
                        // this.editorForm.auto_send_upon_import = data.data.auto_send_upon_import;

                    },
                    err => {
                    }
                )
            },
            formatDate(value) {
                if (value) {
                    return moment(String(value)).format('YYYY-MM-DD')
                }
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            searchFn(params) {
                this.updateParams({searchTerm: params});
                this.loadItems();
                this.isLoading = false;
            },
            selectionChanged(param) {
                console.log(param);
                this.user_client_ids = [];
                param.selectedRows.forEach((value, index) => {
                    const data = {
                        email: value.email,
                        user_id: value.user_id,
                    }
                    this.user_client_ids.push(data)
                });
                console.log(this.user_client_ids);
            },
            sendWelcomeEmail() {
                if (this.user_client_ids.length === 0) {
                    this.$swal({
                        title: 'Error',
                        text: "Please select users first!",
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        // confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {

                    })
                } else {
                    this.isDisabled = true;
                    this.isLoading2 = true;
                    let data = {
                        users: this.user_client_ids,
                        client_id: JSON.parse(localStorage.getItem('client_id')),
                    }
                    API.sendWelcomeEmail(
                        data,
                        data => {
                            this.isDisabled = false;
                            this.isLoading2 = false;
                            if(data.status == 200) {
                                this.$snotify.success(data.message);
                            } else {
                                this.$snotify.error(data.message);
                            }
                        },
                        err => {
                            this.isDisabled = false;
                            this.isLoading2 = false;
                            this.$snotify.error(err, {
                                timeout: 2000,
                                showProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        })
                }
            },
            onSubmitEditor() {
                this.isDisabled1 = true;
                API.updateEmailTemplateEditorContent(
                    this.editorForm,
                    // alert(),
                    data => {
                        this.isDisabled1 = false;
                        this.$snotify.success(data.message, {
                            timeout: 2000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    },
                    err => {
                        this.isDisabled1 = false;
                        this.$snotify.error(err.message, {
                            timeout: 2000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    }
                )
            },


            onSubmit() {

                this.$v.form.$touch();
                if (this.$v.form.$anyError) {
                    return;
                }

                if (!this.image_error) {
                    const fd = new FormData();
                    if (this.selectedFile) {
                        fd.append('file', this.selectedFile, this.selectedFile.name)
                    }
                    fd.append('data', JSON.stringify(this.form))
                    API.updateAppSettings(
                        fd,
                        data => {
                            this.isDisabled = false;
                            this.$v.form.$reset();
                            this.$snotify.success(data.message, {
                                timeout: 2000,
                                showProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        },
                        err => {
                            this.isDisabled = false;
                            this.$snotify.error(err.message, {
                                timeout: 2000,
                                showProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                    )
                } else {
                    this.$swal({
                        type: 'error',
                        title: 'Oops...',
                        text: 'Image size is not valid.',
                    })
                }
            },
            onFileSelected(event) {
                var img = new Image();
                var target_file = event.target.files[0];
                img.src = URL.createObjectURL(target_file);


                img.onload = () => {
                    console.log(img.naturalWidth, img.naturalHeight)
                    if ((img.naturalWidth === 50 && img.naturalHeight === 50) || (img.naturalWidth === 27 && img.naturalHeight === 27)) {
                        this.image_error = false;
                        this.selectedFile = event.target.files[0]
                        this.url = URL.createObjectURL(this.selectedFile)
                    } else {
                        this.image_error = true;
                    }
                }
            },
            getAppSettings() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                }
                API.getAppSettings(
                    data,
                    data => {
                        this.editorForm.editor_content = data.data.editor_content;
                        this.form.header_color = data.data.header_color;
                        this.form.font_color = data.data.font_color;
                        this.form.latitude = data.data.latitude;
                        this.form.longitude = data.data.longitude;
                        this.form.default_map = (data.data.default_map) ? data.data.default_map : 'mapbox://styles/mapbox/light-v10';
                        this.form.require_photo = (data.data.require_photo === 1) ? true : false;
                        this.form.require_lat_lang = (data.data.require_lat_lang === 1) ? true : false;
                        this.form.require_approval = (data.data.require_approval === 1) ? true : false;
                        // this.form.training_complete = (data.data.training_complete === 1) ? true : false;
                        this.url = data.data.image;
                    },
                    err => {
                    }
                )
            },
            getClientSettings() {
                this.clientSettings = JSON.parse(localStorage.getItem('client_settings'));
                if (this.clientSettings) {
                    this.client_url = this.clientSettings.url;
                }
            },
            handleAttachmentChanges(event) {
                // 1. get file object
                let file = event.attachment.file;

                let data = {
                    upload: file
                }

                API.saveEditorImage(
                    data,
                    data => {
                        let attributes = {
                            url: data.url,
                            href: data.url
                        };
                        event.attachment.setAttributes(attributes);
                    },
                    err => {
                    }
                )
                // 2. upload file to remote server with FormData
                // ...

                // 3. if upload success, set back the attachment's URL attribute
                // @param object data from remote server response data after upload.
                // let attributes = {
                //     url: remoteHost + data.path,
                //     href: remoteHost + data.path
                // };
                // event.attachment.setAttributes(attributes);
            },
            removeImage() {
                this.selectedFile = [];
                this.url = '';
            },
            onEditorInput(event) {
            },
            uploader(editor) {
                editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                    return new UploadAdapter(loader);
                };
            },
            onReady(editor) {
                editor.ui.getEditableElement().parentElement.insertBefore(
                    editor.ui.view.toolbar.element,
                    editor.ui.getEditableElement()
                );
            },
            // saveMapStyle(value) {
            //     console.log(value)
            //     this.default_map = value;
            // }
        }, computed: {},
        mounted() {
            this.isLoading = true;
            this.loadItems();
            this.loadItem();
            this.getClientSettings();
        },
        beforeMount() {
        },
        //  onSubmitEditor() {
        //         this.isDisabled1 = true;
        //         API.updateEditorContent(
        //             this.editorForm,
        //             data => {
        //                 this.isDisabled1 = false;
        //                 this.$snotify.success(data.message, {
        //                     timeout: 2000,
        //                     showProgressBar: false,
        //                     closeOnClick: true,
        //                     pauseOnHover: true,
        //                 });
        //             },
        //             err => {
        //                 this.isDisabled1 = false;
        //                 this.$snotify.error(err.message, {
        //                     timeout: 2000,
        //                     showProgressBar: false,
        //                     closeOnClick: true,
        //                     pauseOnHover: true,
        //                 });
        //             }
        //         )
        //     },


    }
</script>
<style scoped>
    .document-editor {
        border: 1px solid var(--ck-color-base-border);
        border-radius: var(--ck-border-radius);

        /* Set vertical boundaries for the document editor. */
        max-height: 700px;

        /* This element is a flex container for easier rendering. */
        display: flex;
        flex-flow: column nowrap;
    }

    .document-editor__toolbar {
        /* Make sure the toolbar container is always above the editable. */
        z-index: 1;

        /* Create the illusion of the toolbar floating over the editable. */
        box-shadow: 0 0 5px hsla(0, 0%, 0%, .2);

        /* Use the CKEditor CSS variables to keep the UI consistent. */
        border-bottom: 1px solid var(--ck-color-toolbar-border);
    }

    /* Adjust the look of the toolbar inside the container. */
    .document-editor__toolbar .ck-toolbar {
        border: 0;
        border-radius: 0;
    }

    /* Make the editable container look like the inside of a native word processor application. */
    .document-editor__editable-container {
        padding: calc(2 * var(--ck-spacing-large));
        background: var(--ck-color-base-foreground);

        /* Make it possible to scroll the "page" of the edited content. */
        overflow-y: scroll;
    }

    .document-editor__editable-container .ck-editor__editable {
        /* Set the dimensions of the "page". */
        width: 15.8cm;
        min-height: 21cm;

        /* Keep the "page" off the boundaries of the container. */
        padding: 1cm 2cm 2cm;

        border: 1px hsl(0, 0%, 82.7%) solid;
        border-radius: var(--ck-border-radius);
        background: white;

        /* The "page" should cast a slight shadow (3D illusion). */
        box-shadow: 0 0 5px hsla(0, 0%, 0%, .1);

        /* Center the "page". */
        margin: 0 auto;
    }

    /* Set the default font for the "page" of the content. */
    .document-editor .ck-content,
    .document-editor .ck-heading-dropdown .ck-list .ck-button__label {
        font: 16px/1.6 "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    /* Adjust the headings dropdown to host some larger heading styles. */
    .document-editor .ck-heading-dropdown .ck-list .ck-button__label {
        line-height: calc(1.7 * var(--ck-line-height-base) * var(--ck-font-size-base));
        min-width: 6em;
    }

    /* Scale down all heading previews because they are way too big to be presented in the UI.
    Preserve the relative scale, though. */
    .document-editor .ck-heading-dropdown .ck-list .ck-button:not(.ck-heading_paragraph) .ck-button__label {
        transform: scale(0.8);
        transform-origin: left;
    }

    /* Set the styles for "Heading 1". */
    .document-editor .ck-content h2,
    .document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
        font-size: 2.18em;
        font-weight: normal;
    }

    .document-editor .ck-content h2 {
        line-height: 1.37em;
        padding-top: .342em;
        margin-bottom: .142em;
    }

    /* Set the styles for "Heading 2". */
    .document-editor .ck-content h3,
    .document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
        font-size: 1.75em;
        font-weight: normal;
        color: hsl(203, 100%, 50%);
    }

    .document-editor .ck-heading-dropdown .ck-heading_heading2.ck-on .ck-button__label {
        color: var(--ck-color-list-button-on-text);
    }

    /* Set the styles for "Heading 2". */
    .document-editor .ck-content h3 {
        line-height: 1.86em;
        padding-top: .171em;
        margin-bottom: .357em;
    }

    /* Set the styles for "Heading 3". */
    .document-editor .ck-content h4,
    .document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
        font-size: 1.31em;
        font-weight: bold;
    }

    .document-editor .ck-content h4 {
        line-height: 1.24em;
        padding-top: .286em;
        margin-bottom: .952em;
    }

    /* Set the styles for "Paragraph". */
    .document-editor .ck-content p {
        font-size: 1em;
        line-height: 1.63em;
        padding-top: .5em;
        margin-bottom: 1.13em;
    }

    /* Make the block quoted text serif with some additional spacing. */
    .document-editor .ck-content blockquote {
        font-family: Georgia, serif;
        margin-left: calc(2 * var(--ck-spacing-large));
        margin-right: calc(2 * var(--ck-spacing-large));
    }

    .ck.ck-editor__editable_inline {
        border: 1px solid #e3e3e3;
        overflow: auto;
        padding: 0 var(--ck-spacing-standard);
    }
    @media (max-width: 1150px) {
        .card .new-card-body {
            padding: 1.25rem 1.437rem !important;
        }
    }
</style>
